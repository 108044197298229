.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url("https://d2lyx5ly60ksu3.cloudfront.net/cdn/cd/363dfaf0f8203b231c2984eb7cec0e51/bootstrap.min.css");

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:hover {
  cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32px' height='32px' viewBox='0 0 32 32' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(100%25,65.490196%25,20%25);fill-opacity:1;' d='M 12.472656 5.996094 L 12.472656 8.519531 L 21.3125 8.519531 L 21.3125 1.578125 L 17.839844 1.578125 L 17.839844 5.996094 Z M 12.472656 5.996094 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(100%25,71.372549%25,33.333333%25);fill-opacity:1;' d='M 12.472656 8.519531 L 12.472656 5.996094 L 7.109375 5.996094 L 7.109375 1.578125 L 3.636719 1.578125 L 3.636719 25.058594 L 10.6875 25.058594 L 10.6875 8.519531 Z M 12.472656 8.519531 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(27.45098%25,97.254902%25,100%25);fill-opacity:1;' d='M 21.3125 15.570312 L 21.3125 8.519531 L 19.527344 8.519531 L 19.527344 32 L 28.363281 32 L 28.363281 15.570312 Z M 21.3125 15.570312 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(60.784314%25,98.431373%25,100%25);fill-opacity:1;' d='M 10.6875 8.519531 L 19.527344 8.519531 L 19.527344 32 L 10.6875 32 Z M 10.6875 8.519531 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(0%25,84.313725%25,87.45098%25);fill-opacity:1;' d='M 21.3125 15.570312 L 28.363281 15.570312 L 21.3125 8.519531 Z M 21.3125 15.570312 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(100%25,85.490196%25,26.666667%25);fill-opacity:1;' d='M 7.109375 0 L 17.839844 0 L 17.839844 5.996094 L 7.109375 5.996094 Z M 7.109375 0 '/%3E%3C/g%3E%3C/svg%3E%0A"),
   auto;
}